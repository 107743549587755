<template>
  <div
    v-if="data"
    class="website-container"
    :class="{'landing-page': this.data[slug] && this.data[slug].fields ? this.data[slug].fields.hideInMenu && !this.data[slug].fields.showSensataLogo : ''}"
  >

    <transition-group
      name="slide" tag="div" class="website-container-inner"
    >
      <div
        v-for="(content, key) in siteContent" 
        class="clear-both"
        :key="'path_' + $route.fullPath + '_' + key + content.sys.id"
        :class="{
          'two-columns': content.fields.type.includes('TwoColumns'),
          'three-columns': content.fields.type.includes('ImageLink'),
        }"
      >
        <Hero
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('Hero')"
          v-on:next="next($event)"
        ></Hero>
        <BlogPost
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('BlogPost')"
          v-on:next="next($event)"
        ></BlogPost>
        <Logos
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('Logos')"
          v-on:next="next($event)"
        ></Logos>
        <ImageLink
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('ImageLink')"
          v-on:next="next($event)"
        ></ImageLink>
        <TwoColumns
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('TwoColumns')"
          v-on:next="next($event)"
        ></TwoColumns>
        <Banner
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('Banner')"
          v-on:next="next($event)"
        ></Banner>
        <Separator
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('Separator')"
          v-on:next="next($event)"
        ></Separator>
        <Social
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('Social')"
          v-on:next="next($event)"
        ></Social>
        <MongoDBChart
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('Chart')"
          v-on:next="next($event)"
        ></MongoDBChart>
        <MongoDBChartFiltered
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('Select+Chart')"
          v-on:next="next($event)"
        ></MongoDBChartFiltered>
        <YouTubeEmbed
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('YouTube')"
          v-on:next="next($event)"
        ></YouTubeEmbed>
        <TableauEmbed
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('TableauEmbed')"
          v-on:next="next($event)"
        ></TableauEmbed>
        <spotifyEmbed
          :key="content.sys.id"
          :data="content"
          v-if="siteContent && content.fields && content.fields.type.includes('SpotifyEmbed')"
          v-on:next="next($event)"
        ></spotifyEmbed>
      </div>
    </transition-group>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'WebContainer',

  transition: 'page',

  props: [ 'data', 'slug' ],

  data () {
    return {
      siteContent: {}
    }
  },

  components: {
    Hero: () => import('Components/web/Hero'),
    BlogPost: () => import('Components/web/BlogPost'),
    Logos: () => import('Components/web/Logos'),
    ImageLink: () => import('Components/web/ImageLink'),
    TwoColumns: () => import('Components/web/TwoColumns'),
    Banner: () => import('Components/web/Banner'),
    Social: () => import('Components/web/Social'),
    Separator: () => import('Components/web/Separator'),
    MongoDBChart: () => import('Components/web/MongoDBChart'),
    YouTubeEmbed: () => import('Components/web/YouTubeEmbed'),
    TableauEmbed: () => import('Components/web/TableauEmbed'),
    SpotifyEmbed: () => import('Components/web/SpotifyEmbed'),
    MongoDBChartFiltered: () => import('Components/web/MongoDBChartFiltered')
  },

  methods: {
  },

  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 150)

    if (this.data[this.slug]) {
      this.siteContent = this.data[this.slug].fields.webContent
    }

    if (this.$route.fullPath.indexOf('/es') === 0) {
      this.$store.commit('SET_SITE_LANG', { lang: 'ES' })
    } else {
      this.$store.commit('SET_SITE_LANG', { lang: 'EN' })      
    }
  }

}

</script>

<style scoped lang="scss">

.clear-both {
  clear: both;
}
.two-columns {
  @include breakpoint($tablet) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    display: inline-block;
    float: left;
    clear: none;
  }
}
.three-columns {
  @include breakpoint($tablet) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 33.3%;
    display: inline-block;
    float: left;
    clear: none;
  }
}
</style>

<style lang="scss">
.el-button--danger {
  border-color: $color-wrapper-emphasis-lighter !important;
  background-color: $color-wrapper-emphasis-lighter !important;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    border-color: rgba($color-wrapper-emphasis-between, 1) !important;
    background-color: rgba($color-wrapper-emphasis-between, 1) !important;
  }
}

/* website start */

/* website end */
</style>